import { __extends } from "tslib";
var REPOSITORY_URL = 'http://github.com/wavesplatform/signer';
export var ERRORS = {
    SIGNER_OPTIONS: 1000,
    NETWORK_BYTE: 1001,
    NOT_AUTHORIZED: 1002,
    PROVIDER_CONNECT: 1003,
    ENSURE_PROVIDER: 1004,
    PROVIDER_INTERFACE: 1005,
    PROVIDER_INTERNAL: 1006,
    API_ARGUMENTS: 1007,
    NETWORK_ERROR: 1008,
    PROVIDER_SIGN_NOT_SUPPORTED: 1009,
};
var errorTemplate = function (error) {
    var details = error.details ? "    Details: " + error.details : undefined;
    var errorArgs = error.errorArgs
        ? "    ErrorArgs: " + error.errorArgs
        : undefined;
    return [
        "Signer error:",
        "    Title: " + error.title,
        "    Type: " + error.type,
        "    Code: " + error.code,
        errorArgs,
        details,
        "    More info: " + REPOSITORY_URL + "#error-codes",
    ]
        .filter(Boolean)
        .join('\n');
};
var SignerError = /** @class */ (function (_super) {
    __extends(SignerError, _super);
    function SignerError(details) {
        var _this = _super.call(this, errorTemplate(details)) || this;
        _this.code = details.code;
        _this.type = details.type;
        // Set the prototype explicitly.
        Object.setPrototypeOf(_this, SignerError.prototype);
        return _this;
    }
    return SignerError;
}(Error));
export { SignerError };
var SignerOptionsError = /** @class */ (function (_super) {
    __extends(SignerOptionsError, _super);
    function SignerOptionsError(args) {
        var _this = _super.call(this, {
            code: ERRORS.SIGNER_OPTIONS,
            title: 'Invalid signer options',
            type: 'validation',
            details: "\n        Invalid signer options: " + args.join(', '),
            errorArgs: args,
        }) || this;
        Object.setPrototypeOf(_this, SignerOptionsError.prototype);
        return _this;
    }
    return SignerOptionsError;
}(SignerError));
export { SignerOptionsError };
var SignerApiArgumentsError = /** @class */ (function (_super) {
    __extends(SignerApiArgumentsError, _super);
    function SignerApiArgumentsError(args) {
        var _this = _super.call(this, {
            code: ERRORS.API_ARGUMENTS,
            title: 'Invalid api method arguments',
            type: 'validation',
            details: "\n        " + args.join('\n        '),
            errorArgs: args,
        }) || this;
        Object.setPrototypeOf(_this, SignerApiArgumentsError.prototype);
        return _this;
    }
    return SignerApiArgumentsError;
}(SignerError));
export { SignerApiArgumentsError };
var SignerNetworkByteError = /** @class */ (function (_super) {
    __extends(SignerNetworkByteError, _super);
    function SignerNetworkByteError(_a) {
        var error = _a.error, node = _a.node;
        var _this = _super.call(this, {
            code: ERRORS.NETWORK_BYTE,
            title: 'Network byte fetching has failed',
            type: 'network',
            details: "\n        Could not fetch network from " + node + ": " + error,
            errorArgs: { error: error, node: node },
        }) || this;
        Object.setPrototypeOf(_this, SignerNetworkByteError.prototype);
        return _this;
    }
    return SignerNetworkByteError;
}(SignerError));
export { SignerNetworkByteError };
var SignerProviderInterfaceError = /** @class */ (function (_super) {
    __extends(SignerProviderInterfaceError, _super);
    function SignerProviderInterfaceError(invalidProperties) {
        var _this = _super.call(this, {
            code: ERRORS.NETWORK_BYTE,
            title: 'Invalid Provider interface',
            type: 'validation',
            details: "\n        Invalid provider properties: " + invalidProperties.join(', '),
            errorArgs: invalidProperties,
        }) || this;
        Object.setPrototypeOf(_this, SignerProviderInterfaceError.prototype);
        return _this;
    }
    return SignerProviderInterfaceError;
}(SignerError));
export { SignerProviderInterfaceError };
var SignerProviderConnectError = /** @class */ (function (_super) {
    __extends(SignerProviderConnectError, _super);
    function SignerProviderConnectError(_a) {
        var error = _a.error, node = _a.node;
        var _this = _super.call(this, {
            code: ERRORS.PROVIDER_CONNECT,
            title: 'Could not connect the Provider',
            type: 'network',
            errorArgs: { error: error, node: node },
        }) || this;
        Object.setPrototypeOf(_this, SignerProviderConnectError.prototype);
        return _this;
    }
    return SignerProviderConnectError;
}(SignerError));
export { SignerProviderConnectError };
var SignerProviderSignIsNotSupport = /** @class */ (function (_super) {
    __extends(SignerProviderSignIsNotSupport, _super);
    function SignerProviderSignIsNotSupport(_a) {
        var error = _a.error, node = _a.node;
        var _this = _super.call(this, {
            code: ERRORS.PROVIDER_SIGN_NOT_SUPPORTED,
            title: 'Method sign is not support for this provider. Use broadcats instead',
            type: 'validation',
            errorArgs: { error: error, node: node },
        }) || this;
        Object.setPrototypeOf(_this, SignerProviderSignIsNotSupport.prototype);
        return _this;
    }
    return SignerProviderSignIsNotSupport;
}(SignerError));
export { SignerProviderSignIsNotSupport };
var SignerEnsureProviderError = /** @class */ (function (_super) {
    __extends(SignerEnsureProviderError, _super);
    function SignerEnsureProviderError(method) {
        var _this = _super.call(this, {
            code: ERRORS.ENSURE_PROVIDER,
            title: 'Provider instance is missing',
            type: 'provider',
            details: "Can't use method: " + method + ". Provider instance is missing",
            errorArgs: { failedMethod: method },
        }) || this;
        Object.setPrototypeOf(_this, SignerProviderConnectError.prototype);
        return _this;
    }
    return SignerEnsureProviderError;
}(SignerError));
export { SignerEnsureProviderError };
var SignerProviderInternalError = /** @class */ (function (_super) {
    __extends(SignerProviderInternalError, _super);
    function SignerProviderInternalError(message) {
        var _this = _super.call(this, {
            code: ERRORS.ENSURE_PROVIDER,
            title: 'Provider internal error',
            type: 'provider',
            details: "Provider internal error: " + message + ". This is not error of signer.",
        }) || this;
        Object.setPrototypeOf(_this, SignerProviderConnectError.prototype);
        return _this;
    }
    return SignerProviderInternalError;
}(SignerError));
export { SignerProviderInternalError };
var SignerAuthError = /** @class */ (function (_super) {
    __extends(SignerAuthError, _super);
    function SignerAuthError(method) {
        var _this = _super.call(this, {
            code: ERRORS.NOT_AUTHORIZED,
            title: 'Authorization error',
            type: 'authorization',
            details: "Can't use method: " + method + ". User must be logged in",
            errorArgs: { failedMethod: method },
        }) || this;
        Object.setPrototypeOf(_this, SignerProviderConnectError.prototype);
        return _this;
    }
    return SignerAuthError;
}(SignerError));
export { SignerAuthError };
var SignerNetworkError = /** @class */ (function (_super) {
    __extends(SignerNetworkError, _super);
    // TODO REMOVE ANY, ADD MORE DETAILS
    function SignerNetworkError(params) {
        return _super.call(this, {
            code: ERRORS.NETWORK_ERROR,
            title: 'Network Error',
            type: 'network',
            details: "Error connect to " + '',
            errorArgs: {},
        }) || this;
    }
    return SignerNetworkError;
}(SignerError));
export { SignerNetworkError };
