import { __assign, __awaiter, __decorate, __generator, __spreadArrays } from "tslib";
import { DEFAULT_OPTIONS } from './constants';
import { makeConsole, makeOptions } from '@waves/client-logs';
import { fetchBalanceDetails } from '@waves/node-api-js/cjs/api-node/addresses';
import { fetchAssetsBalance } from '@waves/node-api-js/cjs/api-node/assets';
import wait from '@waves/node-api-js/cjs/tools/transactions/wait';
import broadcast from '@waves/node-api-js/cjs/tools/transactions/broadcast';
import getNetworkByte from '@waves/node-api-js/cjs/tools/blocks/getNetworkByte';
import { TRANSACTION_TYPE, } from '@waves/ts-types';
import { argsValidators, validateProviderInterface, validateSignerOptions, } from './validation';
import { ERRORS } from './SignerError';
import { errorHandlerFactory } from './helpers';
import { catchProviderError, checkAuth, ensureProvider } from './decorators';
export * from './types';
var Signer = /** @class */ (function () {
    function Signer(options) {
        var _this_1 = this;
        var _a;
        this._issue = function (txList) { return function (data) {
            return _this_1._createPipelineAPI(txList, __assign(__assign({}, data), { type: TRANSACTION_TYPE.ISSUE }));
        }; };
        this._transfer = function (txList) { return function (data) {
            return _this_1._createPipelineAPI(txList, __assign(__assign({}, data), { type: TRANSACTION_TYPE.TRANSFER }));
        }; };
        this._reissue = function (txList) { return function (data) {
            return _this_1._createPipelineAPI(txList, __assign(__assign({}, data), { type: TRANSACTION_TYPE.REISSUE }));
        }; };
        this._burn = function (txList) { return function (data) {
            return _this_1._createPipelineAPI(txList, __assign(__assign({}, data), { type: TRANSACTION_TYPE.BURN }));
        }; };
        this._lease = function (txList) { return function (data) {
            return _this_1._createPipelineAPI(txList, __assign(__assign({}, data), { type: TRANSACTION_TYPE.LEASE }));
        }; };
        this._exchange = function (txList) { return function (data) {
            return _this_1._createPipelineAPI(txList, __assign(__assign({}, data), { type: TRANSACTION_TYPE.EXCHANGE }));
        }; };
        this._cancelLease = function (txList) { return function (data) {
            return _this_1._createPipelineAPI(txList, __assign(__assign({}, data), { type: TRANSACTION_TYPE.CANCEL_LEASE }));
        }; };
        this._alias = function (txList) { return function (data) {
            return _this_1._createPipelineAPI(txList, __assign(__assign({}, data), { type: TRANSACTION_TYPE.ALIAS }));
        }; };
        this._massTransfer = function (txList) { return function (data) {
            return _this_1._createPipelineAPI(txList, __assign(__assign({}, data), { type: TRANSACTION_TYPE.MASS_TRANSFER }));
        }; };
        this._data = function (txList) { return function (data) {
            return _this_1._createPipelineAPI(txList, __assign(__assign({}, data), { type: TRANSACTION_TYPE.DATA }));
        }; };
        this._sponsorship = function (txList) { return function (sponsorship) {
            return _this_1._createPipelineAPI(txList, __assign(__assign({}, sponsorship), { type: TRANSACTION_TYPE.SPONSORSHIP }));
        }; };
        this._setScript = function (txList) { return function (setScript) {
            return _this_1._createPipelineAPI(txList, __assign(__assign({}, setScript), { type: TRANSACTION_TYPE.SET_SCRIPT }));
        }; };
        this._setAssetScript = function (txList) { return function (data) {
            return _this_1._createPipelineAPI(txList, __assign(__assign({}, data), { type: TRANSACTION_TYPE.SET_ASSET_SCRIPT }));
        }; };
        this._invoke = function (txList) { return function (data) {
            return _this_1._createPipelineAPI(txList, __assign(__assign({}, data), { type: TRANSACTION_TYPE.INVOKE_SCRIPT }));
        }; };
        this._logger = makeConsole(makeOptions((_a = options === null || options === void 0 ? void 0 : options.LOG_LEVEL) !== null && _a !== void 0 ? _a : 'production', 'Signer'));
        this._handleError = errorHandlerFactory(this._logger);
        this._options = __assign(__assign({}, DEFAULT_OPTIONS), (options || {}));
        var _b = validateSignerOptions(this._options), isValid = _b.isValid, invalidOptions = _b.invalidOptions;
        if (!isValid) {
            var error = this._handleError(ERRORS.SIGNER_OPTIONS, [
                invalidOptions,
            ]);
            throw error;
        }
        var makeNetworkByteError = function (e) {
            var error = _this_1._handleError(ERRORS.NETWORK_BYTE, [
                {
                    error: e.message,
                    node: _this_1._options.NODE_URL,
                },
            ]);
            _this_1._logger.error(error);
            return error;
        };
        try {
            this._networkBytePromise = getNetworkByte(this._options.NODE_URL).catch(function (e) { return Promise.reject(makeNetworkByteError(e)); });
        }
        catch (e) {
            throw makeNetworkByteError(e);
        }
        this._logger.info('Signer instance has been successfully created. Options: ', options);
    }
    Object.defineProperty(Signer.prototype, "_connectPromise", {
        get: function () {
            return this.__connectPromise || Promise.reject('Has no provider!');
        },
        set: function (promise) {
            this.__connectPromise = promise;
        },
        enumerable: false,
        configurable: true
    });
    Signer.prototype.on = function (event, handler) {
        this.currentProvider.on(event, handler);
        this._logger.info("Handler for \"" + event + "\" has been added.");
        return this;
    };
    Signer.prototype.once = function (event, handler) {
        this.currentProvider.once(event, handler);
        this._logger.info("One-Time handler for \"" + event + "\" has been added.");
        return this;
    };
    Signer.prototype.off = function (event, handler) {
        this.currentProvider.off(event, handler);
        this._logger.info("Handler for \"" + event + "\" has been removed.");
        return this;
    };
    Signer.prototype.broadcast = function (toBroadcast, options) {
        // @ts-ignore
        return broadcast(this._options.NODE_URL, toBroadcast, options); // TODO поправить тип в broadcast
    };
    /**
     * Запросить байт сети
     */
    Signer.prototype.getNetworkByte = function () {
        return this._networkBytePromise;
    };
    /**
     * Устанавливаем провайдер отвечающий за подпись
     * @param provider
     *
     * ```ts
     * import Signer from '@waves/signer';
     * import Provider from '@waves/seed-provider';
     *
     * const waves = new Signer();
     * waves.setProvider(new Provider('SEED'));
     * ```
     */
    Signer.prototype.setProvider = function (provider) {
        return __awaiter(this, void 0, void 0, function () {
            var providerValidation, error;
            var _this_1 = this;
            return __generator(this, function (_a) {
                providerValidation = validateProviderInterface(provider);
                if (!providerValidation.isValid) {
                    error = this._handleError(ERRORS.PROVIDER_INTERFACE, [
                        providerValidation.invalidProperties,
                    ]);
                    throw error;
                }
                this.currentProvider = provider;
                this._logger.info('Provider has been set.');
                this._connectPromise = this._networkBytePromise.then(function (byte) {
                    return provider
                        .connect({
                        NETWORK_BYTE: byte,
                        NODE_URL: _this_1._options.NODE_URL,
                    })
                        .then(function () {
                        _this_1._logger.info('Provider has connected to node.');
                        return provider;
                    })
                        .catch(function (e) {
                        var error = _this_1._handleError(ERRORS.PROVIDER_CONNECT, [
                            {
                                error: e.message,
                                node: _this_1._options.NODE_URL,
                            },
                        ]);
                        _this_1._logger.error(error);
                        return Promise.reject(error);
                    });
                });
                return [2 /*return*/];
            });
        });
    };
    /**
     * Получаем список балансов пользователя (необходимо выполнить login перед использованием)
     * Basic usage example:
     *
     * ```ts
     * await waves.getBalance(); // Возвращает балансы пользователя
     * ```
     */
    Signer.prototype.getBalance = function () {
        var _this_1 = this;
        return Promise.all([
            fetchBalanceDetails(this._options.NODE_URL, this._userData.address).then(function (data) { return ({
                assetId: 'WAVES',
                assetName: 'Waves',
                decimals: 8,
                amount: String(data.available),
                isMyAsset: false,
                tokens: Number(data.available) * Math.pow(10, 8),
                sponsorship: null,
                isSmart: false,
            }); }),
            fetchAssetsBalance(this._options.NODE_URL, this._userData.address).then(function (data) {
                return data.balances.map(function (item) { return ({
                    assetId: item.assetId,
                    assetName: item.issueTransaction.name,
                    decimals: item.issueTransaction.decimals,
                    amount: String(item.balance),
                    isMyAsset: item.issueTransaction.sender ===
                        _this_1._userData.address,
                    tokens: Number(item.balance) *
                        Math.pow(10, item.issueTransaction.decimals),
                    isSmart: !!item.issueTransaction.script,
                    sponsorship: item.sponsorBalance != null &&
                        item.sponsorBalance > Math.pow(10, 8) &&
                        (item.minSponsoredAssetFee || 0) < item.balance
                        ? item.minSponsoredAssetFee
                        : null,
                }); });
            }),
        ]).then(function (_a) {
            var waves = _a[0], assets = _a[1];
            return __spreadArrays([waves], assets);
        });
    };
    /**
     * Получаем информацию о пользователе
     * В данном методе НЕЛЬЗЯ использовать асинхронность.
     * Метод login провайдера должен вызываться синхронно в контексте вызова метода!
     * ```ts
     * await waves.login(); // Авторизуемся. Возвращает адрес и публичный ключ
     * ```
     */
    Signer.prototype.login = function () {
        var _this_1 = this;
        return this.currentProvider.login()
            .then(function (data) {
            _this_1._logger.info('Logged in.');
            _this_1._userData = data;
            return data;
        })
            .catch(function (err) {
            if (err === 'Error: User rejection!') {
                throw err;
            }
            var error = _this_1._handleError(ERRORS.PROVIDER_INTERNAL, [
                err.message,
            ]);
            throw error;
        });
    };
    /**
     * Вылогиниваемся из юзера
     */
    Signer.prototype.logout = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, message, error;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this._connectPromise];
                    case 1:
                        _b.sent();
                        _b.label = 2;
                    case 2:
                        _b.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, this.currentProvider.logout()];
                    case 3:
                        _b.sent();
                        this._userData = undefined;
                        this._logger.info('Logged out.');
                        return [3 /*break*/, 5];
                    case 4:
                        _a = _b.sent();
                        message = _a.message;
                        error = this._handleError(ERRORS.PROVIDER_INTERNAL, message);
                        throw error;
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Подписываем сообщение пользователя (провайдер может устанавливать префикс)
     * @param message
     */
    Signer.prototype.signMessage = function (message) {
        return this._connectPromise.then(function (provider) {
            return provider.signMessage(message);
        });
    };
    /**
     * Подписываем типизированные данные
     * @param data
     */
    Signer.prototype.signTypedData = function (data) {
        return this._connectPromise.then(function (provider) {
            return provider.signTypedData(data);
        });
    };
    /**
     * Получаем список балансов в кторых можно платить комиссию
     */
    Signer.prototype.getSponsoredBalances = function () {
        return this.getBalance().then(function (balance) {
            return balance.filter(function (item) { return !!item.sponsorship; });
        });
    };
    Signer.prototype.batch = function (tsx) {
        var _this_1 = this;
        var sign = function () {
            return _this_1._sign(tsx).then(function (result) { return result; });
        };
        return {
            sign: sign,
            broadcast: function (opt) {
                return sign().then(function (transactions) {
                    return _this_1.broadcast(transactions, opt);
                });
            },
        };
    };
    Signer.prototype.issue = function (data) {
        return this._issue([])(data);
    };
    Signer.prototype.transfer = function (data) {
        return this._transfer([])(data);
    };
    Signer.prototype.reissue = function (data) {
        return this._reissue([])(data);
    };
    Signer.prototype.burn = function (data) {
        return this._burn([])(data);
    };
    Signer.prototype.lease = function (data) {
        return this._lease([])(data);
    };
    Signer.prototype.exchange = function (data) {
        return this._exchange([])(data);
    };
    Signer.prototype.cancelLease = function (data) {
        return this._cancelLease([])(data);
    };
    Signer.prototype.alias = function (data) {
        return this._alias([])(data);
    };
    Signer.prototype.massTransfer = function (data) {
        return this._massTransfer([])(data);
    };
    Signer.prototype.data = function (data) {
        return this._data([])(data);
    };
    Signer.prototype.sponsorship = function (data) {
        return this._sponsorship([])(data);
    };
    Signer.prototype.setScript = function (data) {
        return this._setScript([])(data);
    };
    Signer.prototype.setAssetScript = function (data) {
        return this._setAssetScript([])(data);
    };
    Signer.prototype.invoke = function (data) {
        return this._invoke([])(data);
    };
    Signer.prototype.waitTxConfirm = function (tx, confirmations) {
        return wait(this._options.NODE_URL, tx, { confirmations: confirmations }); // TODO Fix types
    };
    Signer.prototype._createPipelineAPI = function (prevCallTxList, signerTx) {
        var _this_1 = this;
        var _this = this;
        var txs = prevCallTxList.length
            ? __spreadArrays(prevCallTxList, [signerTx]) : [signerTx];
        var chainArgs = Array.isArray(txs) ? txs : [txs];
        return __assign(__assign({}, {
            issue: this._issue(chainArgs),
            transfer: this._transfer(chainArgs),
            reissue: this._reissue(chainArgs),
            burn: this._burn(chainArgs),
            lease: this._lease(chainArgs),
            exchange: this._exchange(chainArgs),
            cancelLease: this._cancelLease(chainArgs),
            alias: this._alias(chainArgs),
            massTransfer: this._massTransfer(chainArgs),
            data: this._data(chainArgs),
            sponsorship: this._sponsorship(chainArgs),
            setScript: this._setScript(chainArgs),
            setAssetScript: this._setAssetScript(chainArgs),
            invoke: this._invoke(chainArgs),
        }), { sign: function () { return _this_1._sign(txs); }, broadcast: function (options) {
                var _a;
                if (((_a = _this.currentProvider) === null || _a === void 0 ? void 0 : _a.isSignAndBroadcastByProvider) === true) {
                    return _this.currentProvider.sign(txs);
                }
                else {
                    return this.sign()
                        // @ts-ignore
                        .then(function (txs) { return _this.broadcast(txs, options); });
                }
            } });
    };
    Signer.prototype._validate = function (toSign) {
        var signerTxs = Array.isArray(toSign) ? toSign : [toSign];
        var validateTx = function (tx) { return argsValidators[tx.type](tx); };
        var knownTxPredicate = function (type) {
            return Object.keys(argsValidators).includes(String(type));
        };
        var unknownTxs = signerTxs.filter(function (_a) {
            var type = _a.type;
            return !knownTxPredicate(type);
        });
        var knownTxs = signerTxs.filter(function (_a) {
            var type = _a.type;
            return knownTxPredicate(type);
        });
        var invalidTxs = knownTxs
            .map(validateTx)
            .filter(function (_a) {
            var isValid = _a.isValid;
            return !isValid;
        });
        if (invalidTxs.length === 0 && unknownTxs.length === 0) {
            return { isValid: true, errors: [] };
        }
        else {
            return {
                isValid: false,
                errors: __spreadArrays(invalidTxs.map(function (_a) {
                    var transaction = _a.transaction, scope = _a.method, invalidFields = _a.invalidFields;
                    return "Validation error for " + scope + " transaction: " + JSON.stringify(transaction) + ". Invalid arguments: " + (invalidFields === null || invalidFields === void 0 ? void 0 : invalidFields.join(', '));
                }), unknownTxs.map(function (tx) {
                    return "Validation error for transaction " + JSON.stringify(tx) + ". Unknown transaction type: " + tx.type;
                })),
            };
        }
    };
    Signer.prototype._sign = function (toSign) {
        var _a;
        var validation = this._validate(toSign);
        if (((_a = this.currentProvider) === null || _a === void 0 ? void 0 : _a.isSignAndBroadcastByProvider) === true) {
            var error = this._handleError(ERRORS.PROVIDER_SIGN_NOT_SUPPORTED, [
                {
                    error: 'PROVIDER_SIGN_NOT_SUPPORTED',
                    node: this._options.NODE_URL,
                },
            ]);
            throw error;
        }
        if (validation.isValid) {
            return this._connectPromise.then(function (provider) { return provider.sign(toSign); }
            // any fixes "Expression produces a union type that is too complex to
            );
        }
        else {
            var error = this._handleError(ERRORS.API_ARGUMENTS, [
                validation.errors,
            ]);
            throw error;
        }
    };
    __decorate([
        ensureProvider
    ], Signer.prototype, "on", null);
    __decorate([
        ensureProvider
    ], Signer.prototype, "once", null);
    __decorate([
        ensureProvider
    ], Signer.prototype, "off", null);
    __decorate([
        ensureProvider,
        checkAuth
    ], Signer.prototype, "getBalance", null);
    __decorate([
        ensureProvider
    ], Signer.prototype, "login", null);
    __decorate([
        ensureProvider
    ], Signer.prototype, "logout", null);
    __decorate([
        ensureProvider
    ], Signer.prototype, "signMessage", null);
    __decorate([
        ensureProvider
    ], Signer.prototype, "signTypedData", null);
    __decorate([
        catchProviderError
    ], Signer.prototype, "_sign", null);
    return Signer;
}());
export { Signer };
// eslint-disable-next-line import/no-default-export
export default Signer;
