import defaultTo from 'ramda/src/defaultTo';
import prop from 'ramda/src/prop';
import ifElse from 'ramda/src/ifElse';
import pipe from 'ramda/src/pipe';
import equals from 'ramda/src/equals';
import gte from 'ramda/src/gte';
import lte from 'ramda/src/lte';
import startsWith from 'ramda/src/startsWith';
import isNil from 'ramda/src/isNil';
import includes from 'ramda/src/includes';
import flip from 'ramda/src/flip';
import always from 'ramda/src/always';
import tryCatch from 'ramda/src/tryCatch';
import { base58Decode } from '@waves/ts-lib-crypto';
var TX_DEFAULTS = {
    MAX_ATTACHMENT: 140,
    ALIAS: {
        AVAILABLE_CHARS: '-.0123456789@_abcdefghijklmnopqrstuvwxyz',
        MAX_ALIAS_LENGTH: 30,
        MIN_ALIAS_LENGTH: 4,
    },
};
export var isArray = function (value) { return Array.isArray(value); };
export var validatePipe = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    return function (value) {
        var isValid = true;
        for (var _i = 0, args_1 = args; _i < args_1.length; _i++) {
            var cb = args_1[_i];
            isValid = !!cb(value);
            if (!isValid) {
                return false;
            }
        }
        return isValid;
    };
};
export var isRequired = function (required) { return function (value) {
    return !required || value != null;
}; };
export var isString = function (value) {
    return typeof value === 'string' || value instanceof String;
};
export var isNumber = function (value) {
    return (typeof value === 'number' || value instanceof Number) &&
        !isNaN(Number(value));
};
export var isNumberLike = function (value) {
    return value != null && !isNaN(Number(value)) && !!(value || value === 0);
};
export var isBoolean = function (value) {
    return value != null && (typeof value === 'boolean' || value instanceof Boolean);
};
export var orEq = flip(includes);
export var exception = function (msg) {
    throw new Error(msg);
};
export var validateBySchema = function (schema, errorTpl) { return function (tx) {
    Object.entries(schema).forEach(function (_a) {
        var key = _a[0], cb = _a[1];
        var value = prop(key, tx || {});
        if (!cb(value)) {
            exception(errorTpl(key, value));
        }
    });
    return true;
}; };
export var isAttachment = ifElse(orEq([null, undefined]), defaultTo(true), ifElse(isString, 
// TODO Fix attachment gte(TX_DEFAULTS.MAX_ATTACHMENT)
pipe(prop('length'), always(true)), defaultTo(false)));
var validateChars = function (chars) { return function (value) {
    return value.split('').every(function (char) { return chars.includes(char); });
}; };
export var isValidAliasName = ifElse(validateChars(TX_DEFAULTS.ALIAS.AVAILABLE_CHARS), pipe(prop('length'), validatePipe(gte(TX_DEFAULTS.ALIAS.MAX_ALIAS_LENGTH), lte(TX_DEFAULTS.ALIAS.MIN_ALIAS_LENGTH))), defaultTo(false));
export var ASSETS = {
    NAME_MIN_BYTES: 4,
    NAME_MAX_BYTES: 16,
    DESCRIPTION_MAX_BYTES: 1000,
};
export var isBase64 = validatePipe(ifElse(isString, startsWith('base64:'), pipe(isNil)));
export var validateType = {
    integer: isNumberLike,
    boolean: isBoolean,
    string: isString,
    binary: isBase64,
};
export var isValidDataPair = function (data) { return !!(validateType[data.type] && validateType[data.type](data.value)); };
export var isValidData = validatePipe(isRequired(true), pipe(prop('key'), validatePipe(isString, function (key) { return !!key; })), isValidDataPair);
export var isPublicKey = validatePipe(isString, tryCatch(function (v) { return base58Decode(v).length === 32; }, always(false)));
export var isValidAssetName = validatePipe(isString, pipe(prop('length'), ifElse(lte(ASSETS.NAME_MIN_BYTES), gte(ASSETS.NAME_MAX_BYTES), defaultTo(false))));
export var isValidAssetDescription = validatePipe(isString, pipe(prop('length'), gte(ASSETS.DESCRIPTION_MAX_BYTES)));
export var isAssetId = validatePipe(ifElse(orEq(['', null, undefined, 'WAVES']), defaultTo(true), isString));
export var isAlias = function (value) { return value.startsWith('alias:'); };
// TODO fix validator!!!
export var isValidAddress = isString;
export var isValidAlias = pipe(function (value) { return value.split(':')[2]; }, isValidAliasName);
export var isRecipient = validatePipe(isString, ifElse(isAlias, isValidAlias, isValidAddress));
var orderScheme = {
    orderType: orEq(['sell', 'buy']),
    senderPublicKey: isPublicKey,
    matcherPublicKey: isPublicKey,
    version: orEq([undefined, 0, 1, 2, 3]),
    assetPair: validatePipe(isRequired(true), pipe(prop('amountAsset'), isAssetId), pipe(prop('priceAsset'), isAssetId)),
    price: isNumberLike,
    amount: isNumberLike,
    matcherFee: isNumberLike,
    expiration: isNumberLike,
    timestamp: isNumber,
    proofs: ifElse(isArray, defaultTo(true), orEq([undefined])),
};
var v12OrderScheme = {
    matcherFeeAssetId: orEq([undefined, null, 'WAVES']),
};
var v3OrderScheme = {
    matcherFeeAssetId: isAssetId,
};
// eslint-disable-next-line @typescript-eslint/no-empty-function
export var noop = function () {
};
// TODO!!!
var validateOrder = validateBySchema(orderScheme, noop);
var validateOrderV2 = validateBySchema(v12OrderScheme, noop);
var validateOrderV3 = validateBySchema(v3OrderScheme, noop);
export var orderValidator = validatePipe(validateOrder, ifElse(pipe(prop('version'), equals(3)), validateOrderV3, validateOrderV2));
