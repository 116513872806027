import { SignerError, ERRORS } from './SignerError';
var getErrorHandler = function (signer) {
    return signer._handleError;
};
export var ensureProvider = function (target, propertyKey, descriptor) {
    var origin = descriptor.value;
    descriptor.value = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var provider = this.currentProvider;
        if (!provider) {
            var handler = getErrorHandler(this);
            var error = handler(ERRORS.ENSURE_PROVIDER, [propertyKey]);
            throw error;
        }
        return origin.apply(this, args);
    };
};
export var catchProviderError = function (target, propertyKey, descriptor) {
    var origin = descriptor.value;
    descriptor.value = function () {
        var _this = this;
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return origin.apply(this, args).catch(function (e) {
            if (e === 'Error: User rejection!') {
                return Promise.reject(e);
            }
            if (e instanceof SignerError) {
                return Promise.reject(e);
            }
            var handler = getErrorHandler(_this);
            var error = handler(ERRORS.PROVIDER_INTERNAL, [e.message]);
            _this._console.error(error);
            return Promise.reject(e);
        });
    };
};
export var checkAuth = function (target, propertyKey, descriptor) {
    var origin = descriptor.value;
    descriptor.value = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        if (this.currentProvider.user == null) {
            var handler = getErrorHandler(this);
            var error = handler(ERRORS.NOT_AUTHORIZED, [propertyKey]);
            throw error;
        }
        return origin.apply(this, args);
    };
};
export var catchNetworkErrors = function (checkData) { return function (target, propertyKey, descriptor) {
    var origin = descriptor.value;
    descriptor.value = function () {
        var _this = this;
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        if (checkData.isMatcher) {
            // TODO
            // if (!this._options.MATCHER_URL) {
            //     const error = new SignerError(
            //         ERROR_CODE_MAP.NO_MATCHER_URL_PROVIDED,
            //         void 0
            //     );
            //
            //     this._console.error(error);
            //
            //     return Promise.reject(error);
            // }
        }
        return origin.apply(this, args).catch(function (e) {
            if (e instanceof SignerError) {
                return Promise.reject(e);
            }
            var handler = getErrorHandler(_this);
            // TODO Provide more details for request error!
            var error = handler(ERRORS.NETWORK_ERROR, [{}]);
            _this._console.error(error);
            return Promise.reject(error);
        });
    };
}; };
