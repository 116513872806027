export var DEFAULT_OPTIONS = {
    NODE_URL: 'https://nodes.wavesnodes.com',
    LOG_LEVEL: 'production',
};
export var DEFAULT_BROADCAST_OPTIONS = {
    chain: false,
    confirmations: -1,
};
export var MAX_ALIAS_LENGTH = 30;
export var SMART_ASSET_EXTRA_FEE = 0.004 * Math.pow(10, 8);
