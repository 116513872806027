var _a;
import { __spreadArrays } from "tslib";
import { ERRORS, SignerApiArgumentsError, SignerAuthError, SignerEnsureProviderError, SignerNetworkByteError, SignerNetworkError, SignerOptionsError, SignerProviderConnectError, SignerProviderSignIsNotSupport, SignerProviderInterfaceError, SignerProviderInternalError, } from './SignerError';
var ERRORS_MAP = (_a = {},
    _a[ERRORS.SIGNER_OPTIONS] = SignerOptionsError,
    _a[ERRORS.NETWORK_BYTE] = SignerNetworkByteError,
    _a[ERRORS.PROVIDER_INTERFACE] = SignerProviderInterfaceError,
    _a[ERRORS.API_ARGUMENTS] = SignerApiArgumentsError,
    _a[ERRORS.PROVIDER_CONNECT] = SignerProviderConnectError,
    _a[ERRORS.PROVIDER_SIGN_NOT_SUPPORTED] = SignerProviderSignIsNotSupport,
    _a[ERRORS.ENSURE_PROVIDER] = SignerEnsureProviderError,
    _a[ERRORS.PROVIDER_INTERNAL] = SignerProviderInternalError,
    _a[ERRORS.NOT_AUTHORIZED] = SignerAuthError,
    _a[ERRORS.NETWORK_ERROR] = SignerNetworkError,
    _a);
export var errorHandlerFactory = function (logger) { return function (errorCode, parameters) {
    var _a;
    var error = new ((_a = ERRORS_MAP[errorCode]).bind.apply(_a, __spreadArrays([void 0], (parameters || []))))();
    logger.log(error.toString());
    throw error;
}; };
