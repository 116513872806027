var _a;
import { __assign } from "tslib";
import defaultTo from 'ramda/src/defaultTo';
import prop from 'ramda/src/prop';
import ifElse from 'ramda/src/ifElse';
import pipe from 'ramda/src/pipe';
import equals from 'ramda/src/equals';
import lte from 'ramda/src/lte';
import not from 'ramda/src/not';
import { noop, isNumberLike, isNumber, isBoolean, validatePipe, isRequired, orEq, isArray, isString, validateBySchema as validateBySheme, isAttachment, isPublicKey, isValidAssetName, isValidAssetDescription, isBase64, isRecipient, isAssetId, isValidData, orderValidator, isValidAliasName, } from './validators';
import { TRANSACTION_TYPE } from '@waves/ts-types';
var shouldValidate = pipe(equals(undefined), not);
var validateOptional = function (validator) {
    return ifElse(shouldValidate, validator, defaultTo(true));
};
// waves-transaction validator can't collect errors for each invalid field.
// This method does.
export var validator = function (scheme, method) { return function (transaction) {
    var _a;
    var invalidFields = [];
    for (var _i = 0, _b = Object.entries(scheme); _i < _b.length; _i++) {
        var _c = _b[_i], fieldName = _c[0], validationScheme = _c[1];
        try {
            validateBySheme((_a = {}, _a[fieldName] = validationScheme, _a), 
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            noop)(transaction);
        }
        catch (error) {
            invalidFields.push(fieldName);
        }
    }
    return {
        isValid: invalidFields.length === 0,
        transaction: transaction,
        method: method,
        invalidFields: invalidFields,
    };
}; };
var getCommonValidators = function (transactionType) { return ({
    type: equals(transactionType),
    version: validateOptional(orEq([undefined, 1, 2, 3])),
    senderPublicKey: validateOptional(isPublicKey),
    fee: validateOptional(isNumberLike),
    proofs: validateOptional(isArray),
}); };
export var issueArgsScheme = __assign(__assign({}, getCommonValidators(TRANSACTION_TYPE.ISSUE)), { name: isValidAssetName, description: validateOptional(isValidAssetDescription), quantity: isNumberLike, decimals: isNumber, reissuable: validateOptional(isBoolean), script: validateOptional(isBase64), chainId: validateOptional(isNumber) });
export var issueArgsValidator = validator(issueArgsScheme, 'issue');
export var transferArgsScheme = __assign(__assign({}, getCommonValidators(TRANSACTION_TYPE.TRANSFER)), { amount: isNumberLike, recipient: isRecipient, assetId: validateOptional(isAssetId), feeAssetId: validateOptional(isAssetId), attachment: validateOptional(isAttachment) });
export var transferArgsValidator = validator(transferArgsScheme, 'transfer');
export var reissueArgsScheme = __assign(__assign({}, getCommonValidators(TRANSACTION_TYPE.REISSUE)), { assetId: isAssetId, quantity: isNumberLike, reissuable: isBoolean, chainId: validateOptional(isNumber) });
export var reissueArgsValidator = validator(reissueArgsScheme, 'reissue');
export var burnArgsScheme = __assign(__assign({}, getCommonValidators(TRANSACTION_TYPE.BURN)), { 
    // TODO isAssetId (not WAVES)
    assetId: isString, amount: isNumberLike, chainId: validateOptional(isNumber) });
export var burnArgsValidator = validator(burnArgsScheme, 'burn');
export var leaseArgsScheme = __assign(__assign({}, getCommonValidators(TRANSACTION_TYPE.LEASE)), { amount: isNumberLike, recipient: isRecipient });
export var leaseArgsValidator = validator(leaseArgsScheme, 'lease');
export var cancelLeaseArgsScheme = __assign(__assign({}, getCommonValidators(TRANSACTION_TYPE.CANCEL_LEASE)), { leaseId: pipe(isString), chainId: validateOptional(isNumber) });
export var cancelLeaseArgsValidator = validator(cancelLeaseArgsScheme, 'cancel lease');
export var aliasArgsScheme = __assign(__assign({}, getCommonValidators(TRANSACTION_TYPE.ALIAS)), { alias: function (value) {
        return typeof value === 'string' ? isValidAliasName(value) : false;
    } });
export var aliasArgsValidator = validator(aliasArgsScheme, 'alias');
export var massTransferArgsScheme = __assign(__assign({}, getCommonValidators(TRANSACTION_TYPE.MASS_TRANSFER)), { transfers: validatePipe(isArray, pipe(prop('length'), lte(0)), function (data) {
        return data.every(validatePipe(isRequired(true), pipe(prop('recipient'), isRecipient), pipe(prop('amount'), isNumberLike)));
    }), assetId: validateOptional(isAssetId), attachment: validateOptional(isAttachment) });
export var massTransferArgsValidator = validator(massTransferArgsScheme, 'mass transfer');
export var dataArgsScheme = __assign(__assign({}, getCommonValidators(TRANSACTION_TYPE.DATA)), { data: function (data) {
        return isArray(data) && data.every(function (item) { return isValidData(item); });
    } });
export var dataArgsValidator = validator(dataArgsScheme, 'data'); // TODO fix any
export var setScriptArgsScheme = __assign(__assign({}, getCommonValidators(TRANSACTION_TYPE.SET_SCRIPT)), { script: isBase64, chainId: validateOptional(isNumber) });
export var setScriptArgsValidator = validator(setScriptArgsScheme, 'set script');
export var sponsorshipArgsScheme = __assign(__assign({}, getCommonValidators(TRANSACTION_TYPE.SPONSORSHIP)), { 
    // TODO Add not WAVES ASSET ID
    assetId: isString, minSponsoredAssetFee: isNumberLike });
export var sponsorshipArgsValidator = validator(sponsorshipArgsScheme, 'sponsorship');
export var exchangeArgsScheme = __assign(__assign({}, getCommonValidators(TRANSACTION_TYPE.EXCHANGE)), { order1: validatePipe(isRequired(true), orderValidator), order2: validatePipe(isRequired(true), orderValidator), amount: isNumberLike, price: isNumberLike, buyMatcherFee: isNumberLike, sellMatcherFee: isNumberLike });
export var exchangeArgsValidator = validator(exchangeArgsScheme, 'exchange');
export var setAssetScriptArgsScheme = __assign(__assign({}, getCommonValidators(TRANSACTION_TYPE.SET_ASSET_SCRIPT)), { script: isBase64, assetId: isAssetId, chainId: validateOptional(isNumber) });
export var setAssetScriptArgsValidator = validator(setAssetScriptArgsScheme, 'set asset script');
export var invokeArgsScheme = __assign(__assign({}, getCommonValidators(TRANSACTION_TYPE.INVOKE_SCRIPT)), { dApp: isRecipient, call: validateOptional(validatePipe(pipe(prop('function'), isString), pipe(prop('function'), prop('length'), lte(0)), pipe(prop('args'), isArray))), payment: validateOptional(validatePipe(isArray, function (data) {
        return data.every(validatePipe(pipe(prop('amount'), isNumberLike), pipe(prop('assetId'), isAssetId)));
    })), feeAssetId: validateOptional(isAssetId), chainId: validateOptional(isNumber) });
export var invokeArgsValidator = validator(invokeArgsScheme, 'invoke');
export var argsValidators = (_a = {},
    _a[TRANSACTION_TYPE.ISSUE] = issueArgsValidator,
    _a[TRANSACTION_TYPE.TRANSFER] = transferArgsValidator,
    _a[TRANSACTION_TYPE.REISSUE] = reissueArgsValidator,
    _a[TRANSACTION_TYPE.BURN] = burnArgsValidator,
    _a[TRANSACTION_TYPE.LEASE] = leaseArgsValidator,
    _a[TRANSACTION_TYPE.CANCEL_LEASE] = cancelLeaseArgsValidator,
    _a[TRANSACTION_TYPE.ALIAS] = aliasArgsValidator,
    _a[TRANSACTION_TYPE.MASS_TRANSFER] = massTransferArgsValidator,
    _a[TRANSACTION_TYPE.DATA] = dataArgsValidator,
    _a[TRANSACTION_TYPE.SET_SCRIPT] = setScriptArgsValidator,
    _a[TRANSACTION_TYPE.SPONSORSHIP] = sponsorshipArgsValidator,
    _a[TRANSACTION_TYPE.EXCHANGE] = exchangeArgsValidator,
    _a[TRANSACTION_TYPE.SET_ASSET_SCRIPT] = setAssetScriptArgsValidator,
    _a[TRANSACTION_TYPE.INVOKE_SCRIPT] = invokeArgsValidator,
    _a);
export var validateSignerOptions = function (options) {
    var res = {
        isValid: true,
        invalidOptions: [],
    };
    var isValidLogLevel = function (level) {
        return ['verbose', 'production', 'error'].includes(String(level));
    };
    if (!isString(options.NODE_URL)) {
        res.isValid = false;
        res.invalidOptions.push('NODE_URL');
    }
    if (!validateOptional(isValidLogLevel)(options.LOG_LEVEL)) {
        res.isValid = false;
        res.invalidOptions.push('debug');
    }
    return res;
};
export var validateProviderInterface = function (provider) {
    var isFunction = function (value) { return typeof value === 'function'; };
    var scheme = {
        connect: isFunction,
        login: isFunction,
        logout: isFunction,
        signMessage: isFunction,
        signTypedData: isFunction,
        sign: isFunction,
    };
    var invalidProperties = [];
    for (var _i = 0, _a = Object.entries(scheme); _i < _a.length; _i++) {
        var _b = _a[_i], fieldName = _b[0], validator_1 = _b[1];
        if (!validator_1(provider[fieldName])) {
            invalidProperties.push(fieldName);
        }
    }
    return {
        isValid: invalidProperties.length === 0,
        invalidProperties: invalidProperties,
    };
};
